

import { DxToast } from 'devextreme-vue/toast';
import DxNumberBox from 'devextreme-vue/number-box';
import { DxTextArea } from 'devextreme-vue/text-area';
import { DxSelectBox } from 'devextreme-vue/select-box';
import DxRadioGroup from 'devextreme-vue/radio-group';
import DxDateBox from 'devextreme-vue/date-box';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxBox, DxItem } from 'devextreme-vue/box';
import DxTabs from 'devextreme-vue/tabs';
/*
import {
  DxRangeSelector,
  DxScale,
} from 'devextreme-vue/range-selector';
*/
import { DxSlider } from 'devextreme-vue/slider';
import DxButton from 'devextreme-vue/button';
import { DxLoadPanel } from 'devextreme-vue/load-panel';

import moment from 'moment';

import SurveyService from "../services/survey-service";

// import vue3starRatings from "vue3-star-ratings";
const format = (value) => `${value}%`;

export default {
  components: {
    DxToast,
    DxNumberBox,
    DxTextArea,
    DxSelectBox,
    DxRadioGroup,
    DxDateBox,
    DxCheckBox,
    DxTabs,
    //DxRangeSelector,
    //DxScale,
    DxButton,
    DxLoadPanel,
    DxSlider,
    DxBox,
    DxItem,
    // vue3starRatings
  },
  data() {
    return {
      now: new Date(),
      formData: {
        pregunta: '',
        respuesta: null
      },
      loading: false,
      surveyCode: null,
      dataSourceSurvey: null,
      isVisible: false,
      message: '',
      type: 'info',
      searchModeOption: 'contains',
      searchExprOption: 'nombre',
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      howDataBeforeSearchOption: false,
      range: 0,
      loadingVisible: false,
      position: { of: '#sourcesurvey' },
      showIndicator: true,
      shading: true,
      showPane: true,
      hideOnOutsideClick: false,
      rating: 0,
      label: {
        visible: true,
        position: 'top',
        format,
      },
      tooltip: {
        enabled: true,
        showMode: 'always',
        position: 'bottom',
        format,
      },
      errorMessage: [],
      showDataBeforeSearchOption: true,
    }
  },
  created() {
    this.surveyCode = this.$route.params.surveyCode;
    this.surveyService = new SurveyService();
  },
  mounted() {
    this.surveyService.getSurveyToken(this.surveyCode).then(data => {
      if (typeof (data.error) === "undefined") {
        this.errorMessage = data;
        this.dataSourceSurvey = data;
      } else {
        this.type = 'error';
        this.message = data.error;
        this.isVisible = true;
        this.errorMessage = data.error;
      }

    });
  },
  methods: {
    send() {
      this.loadingVisible = true;
      this.dataSourceSurvey.token = this.surveyCode;
      //console.log(this.dataSourceSurvey);
      this.surveyService.createSurvey(this.dataSourceSurvey).then(data => {
        if (typeof (data.error) === "undefined") {
          this.loadingVisible = false;
          this.dataSourceSurvey = null;
          this.errorMessage = data;
        } else {
          this.loadingVisible = false;
          this.type = 'error';
          this.message = data.error;
          this.isVisible = true;
          this.errorMessage = data.error;
        }
      });
    },
    valueChangedTab(event, id_config_encuesta_detalle) {
      let preguntas = this.dataSourceSurvey.preguntas;
      preguntas.forEach(pregunta => {
        if (pregunta.id_config_encuesta_detalle == id_config_encuesta_detalle) {
          if (pregunta.id_tipo_dato == 2) {
            pregunta.valor_texto = event.itemData.nombre;
          }

        }
      });
    },
    valueChangedText(event, id_config_encuesta_detalle) {
      let preguntas = this.dataSourceSurvey.preguntas;
      preguntas.forEach(pregunta => {
        if (pregunta.id_config_encuesta_detalle == id_config_encuesta_detalle) {
          if (pregunta.id_tipo_dato == 1) {
            pregunta.valor_numerico = event.value;
          }
          if (pregunta.id_tipo_dato == 2) {
            pregunta.valor_texto = event.value;
          }
        }
      });
    },
    valueChangedRangeSelector(event, id_config_encuesta_detalle) {
      let preguntas = this.dataSourceSurvey.preguntas;
      preguntas.forEach(pregunta => {
        if (pregunta.id_config_encuesta_detalle == id_config_encuesta_detalle) {
          if (pregunta.id_tipo_dato == 1) {
            pregunta.valor_numerico = event.value;
          }
        }
      });
    },
    valueChangedSelect(event, id_config_encuesta_detalle) {
      let preguntas = this.dataSourceSurvey.preguntas;
      preguntas.forEach(pregunta => {
        if (pregunta.id_config_encuesta_detalle == id_config_encuesta_detalle) {
          pregunta.opciones.forEach(opcion => {
            if (event.value == opcion.id_config_encuesta_detalle_opcion) {
              if (pregunta.id_tipo_dato == 2) {
                pregunta.valor_texto = opcion.nombre;
              }
            }
          });
        }
      });
    },
    valueChangedRadioGroup(event, id_config_encuesta_detalle) {
      let preguntas = this.dataSourceSurvey.preguntas;
      preguntas.forEach(pregunta => {
        if (pregunta.id_config_encuesta_detalle == id_config_encuesta_detalle) {
          pregunta.opciones.forEach(opcion => {
            if (event.value == opcion.id_config_encuesta_detalle_opcion) {
              if (pregunta.id_tipo_dato == 2) {
                pregunta.valor_texto = opcion.nombre;
              }
            }
          });

        }
      });
    },
    valueChangedDate(event, id_config_encuesta_detalle) {
      let preguntas = this.dataSourceSurvey.preguntas;
      preguntas.forEach(pregunta => {
        if (pregunta.id_config_encuesta_detalle == id_config_encuesta_detalle) {
          if (pregunta.id_tipo_dato == 4) {
            pregunta.valor_texto = moment(event.value).format('YYYY/MM/DD HH:mm');
          }
          if (pregunta.id_tipo_dato == 2) {
            pregunta.valor_texto = moment(event.value).format('YYYY/MM/DD HH:mm');
          }
        }
      });
    },
    valueChangedCheckBox(event, id_config_encuesta_detalle) {
      let preguntas = this.dataSourceSurvey.preguntas;
      preguntas.forEach(pregunta => {
        if (pregunta.id_config_encuesta_detalle == id_config_encuesta_detalle) {
          pregunta.valor_texto = pregunta.opciones[0].nombre;
          if (pregunta.id_tipo_dato == 3) {
            pregunta.valor_boleano = event.value;
          }
        }
      });
    },
    ratingOnClik(id_config_encuesta_detalle) {
      let preguntas = this.dataSourceSurvey.preguntas;
      preguntas.forEach(pregunta => {
        if (pregunta.id_config_encuesta_detalle == id_config_encuesta_detalle) {
          if (pregunta.id_tipo_dato == 1) {
            pregunta.valor_numerico = pregunta.respuesta;
          }
          if (pregunta.id_tipo_dato == 2) {
            pregunta.valor_texto = pregunta.respuesta;
          }
        }
      });
    },
    onShown() {
      setTimeout(() => {
        this.loadingVisible = false;
      }, 1000);
    },
    onHidden() {
      //this.employeeInfo = employee;
      this.loadingVisible = false;
    },
  },
}
